.menteeOuter{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 65px);
    justify-content: flex-start;
}

.menteeContainer{
    width: 100%;
    height:100%;
    overflow: auto;
}

.menteeList .studentTable td {
    border-bottom: 1px solid var(--grey-3);
    color: var(--black-3);
    font-family: Inter Regular;
    font-size: 16px;
    padding: var(--spacing-12px) var(--spacing-28px);
}

.menteeList table th {
    background: var(--black-1);
    color: var(--white);
    font-family: Inter Light BETA;
    padding: var(--spacing-20px) var(--spacing-28px);
}
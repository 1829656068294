.formGroups{
    display: block;
    position: relative;
}

.formGroups label{
    display: block;
    position: relative;
    font-size: 14px;
    line-height: 120%;
    font-family: 'Inter Regular';
    color: var(--black-2);
    margin-bottom: 5px;
}

.impRed{
    color: var(--danger-5);
}

.formGroups input{
    background: var(--grey-5);
    min-height: 40px;
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid var(--grey-4);
    border-radius: 10px;
}

.formGroups input:focus{
    outline: none;
}

.FormError {
    font-size: 12px;
    color: var(--danger-5);
}
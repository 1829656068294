.feedbackData{
    padding: 30px;
    max-height: calc(100vh - 150px);
}

.feedbackData .callBox{
    color: var(--black-1);
    background: var(--grey-3);
}

.FeedbackWrap{
    padding: 30px;
}

.viewFeedbackWrappper .FeedbackWrap .callTypes{
    position: relative;
}

.viewFeedbackWrappper .FeedbackWrap .callTypes::after{
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 2px;
    background: var(--grey-3);
}

.viewFeedbackWrappper .FeedbackWrap .callButton.active::after{
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 2px;
    background: var(--primary-5);
    z-index: 9;
}

.feedbackBoxAll{
    padding: 15px 30px;
    border-radius: 10px;
    background: var(--grey-5);
    max-width: 630px;
}

.feedbackQuestion{
    font-size: 18px;
    line-height: 120%;
    font-family: 'inter regular';
    color: var(--black-1);
}

.ratingScale{
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 2px;
    position: relative;
}

.ratingScale span{
    display: block;
    position: relative;
    max-width: 85px;
    width: 100%;
    padding-top: 10px;
    text-align: right;
}

.ratingScale span:before{
    content: "";
    min-width: 100%;
    min-height: 6px;
    background: var(--primary-1);
    position: absolute;
    top: 0;
    left: 0;
}

.ratingScale span.active::before{
    background: var(--primary-5);
}

.ratingScale .useless{
    position: absolute;
    text-align: left;
}

.ratingScale .useless::before{
    display: none;
}

.feedbackAnswer{
    font-size: 18px;
    line-height: 150%;
    color: var(--primary-5);
}

.viewFeedbackWrappper .FeedbackWrap .callTypes{
    padding-bottom: 5px;
}

.viewFeedbackWrappper .FeedbackWrap .callButton.active::after{
    bottom: -10px;
}

@media (max-width: 767px){
    .feedbackData{
        padding: 30px 15px;
    }

    .FeedbackWrap {
        padding: 30px 15px;
    }

    .feedbackBoxAll {
        padding: 15px 15px;
    }

    .feedbackQuestion,
    .feedbackAnswer {
        font-size: 16px;
    }

    .ratingScale span{
        font-size: 14px;
    }
}
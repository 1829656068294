.mentorProfileOuter{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 65px);
    justify-content: flex-start;

}
.mentorProfileContainer{
    width: 100%;
    height: 100%;
    padding: var(--spacing-28px);
    padding-right: 0;
    display: flex;
    overflow: auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.mentorProfile{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-8px);
}

.mentorProfile .mentorImg{
    width: 105px;
    height: 105px;
    border-radius: 50%;
    object-fit: cover;
}

.mentorDetails {
    margin-left: var(--spacing-16px);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-20px);
}

.mentorDetails .mentorName{
    font-size: 32px;
    font-family: 'Inter Semi Bold';
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.mentorDetails .designation{
    font-size: 18px;
    color: var(--black-3);
}

.linkdinBtn{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-4px);
    color: var(--primary-5);
    font-size: 18px;
    margin-top: var(--spacing-8px);
    cursor: pointer;
    font-family: 'Inter Regular';
}

.tabsContainer{
    margin-top: var(--spacing-28px);
    width:100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* gap: var(--spacing-36px); */
    /* border-bottom: 2px solid var(--grey-3); */
}

.tab{
    cursor: pointer;
    transition: 0.5s;
    border-bottom: 2px solid var(--grey-3);
    font-family: 'Inter Regular';
    padding:0 19px var(--spacing-8px);
}
.tab.active{
    color: var(--primary-5);
    border-color: var(--primary-5);
}

.tabContentOuter{
    max-width: 600px;
    padding-top: var(--spacing-24px);
    width: 100%;
    position: relative;
    display: block;
    position: relative;
}

.tabContent.active{
    visibility: visible;
    opacity: 1;
}

.tabContent{
    position: absolute;
    transition: 0.3s;
    visibility: hidden;
    opacity: 0;
    
    width: 100%;
}

.tabContent .text{
    font-size: 14px;
    color: var(--black-4);
}

.tabContent .heading{
    font-size: 14px;
    font-family: 'Inter Medium';
    color: var(--black-3);
}

.togglerContainer{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-8px);
}
.toggler{
    cursor: pointer;
    position: relative;
    width: 33px;
    height: 18px;
    background: var(--grey-2);
    border-radius: 30px;
    transition: 0.3s;
}

.togglerBtn{
    transition: 0.3s;
    position: absolute;
    left: 3px;
    top: 2.5px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: var(--white);
}
.toggler.active{
    background: var(--primary-5);
}
.toggler.active .togglerBtn{
    left: 18px;
    background: var(--white);
}

.togglerContainer p{
    font-size: 14px;
    font-family: 'ns-semibold';
    color: var(--black-3);
}
.weeklyTable{
    margin-top: var(--spacing-32px);
    width: max-content;
    text-align: left;
}
.weeklyTable td{
    font-size: 12px;
    color: var(--black-3);
    padding: var(--spacing-12px) var(--spacing-28px);
}

.weeklyTable tr td:nth-child(1){
    padding-left: 0;
}
.weeklyTable tr td:nth-child(2){
    font-family: 'Inter Medium';
}

.togglers{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-16px);
}
.tabToggler{
    cursor: pointer;
    font-size: 16px;
    color: var(--black-3);
    font-family: 'Inter Regular';
    transition: 0.5s;
}
.tabToggler.active{
    color: var(--primary-5);
}

.cardsOuter{
    margin-top: var(--spacing-20px);
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-20px);
}

.card{
    width: 100%;
    padding: var(--spacing-20px);
    border-radius: 10px;
    border: 1px solid var(--grey-3);
    background: var(--grey-5);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: var(--spacing-12px);
}
.cardDetails{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: var(--spacing-12px);
}
.card .cardImg{
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
    border: 1px solid var(--grey-3);
}

.callerDetails .name{
    font-size: 16px;
    font-family: 'Inter Semi Bold';
}

.callerDetails .mail{
    color: var(--black-5);
    font-size: 12px;
    margin-top: var(--spacing-4px);
}

.primaryTag{
    width: max-content;
    background: var(--primary-6);
    border-radius: 100px;
    font-size: 14px;
    color: var(--primary-5);
    padding: var(--spacing-4px) var(--spacing-12px);
}
.greyTag{
    width: max-content;
    background: var(--grey-2);
    border-radius: 100px;
    font-size: 14px;
    color: var(--black-4);
    padding: var(--spacing-4px) var(--spacing-12px);
}
.successTag{
    width: max-content;
    background: var(--success-7);
    border-radius: 100px;
    font-size: 14px;
    color: var(--success-6);
    padding: var(--spacing-4px) var(--spacing-12px);
}

.timeSlots {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.timeSlot {
    display: flex;
    justify-content: center;
    align-items: center;
}

.timeSlot input {
    width: 80px;
}

.tags{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-8px);
}

.card .tags{
    margin-top: var(--spacing-12px);
}

.primaryOutlineBtn{
    cursor: pointer;
    background: var(--white);
    border-radius: 5px;
    color: var(--primary-5);
    border: 1px solid var(--primary-5);
    padding: var(--spacing-8px) var(--spacing-16px);
}

.feedbackRadioGroup{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.feedbackRadioGroup .label{
    font-size: 14px;
    color: var(--grey-1);
}
.sessionFeedbackBody{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-24px);
    overflow: auto;
    height: calc(100% - 110px);
}

.feedbackRadioGroup.flexStart{
    justify-content: flex-start;
    gap: var(--spacing-60px);
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: var(--grey-1);
    width: 100%;
}
[type="radio"]:checked + label{
    color: var(--black-1);
}

.option:focus-within{
    border-color:var(--primary-4) ;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 1px;
    width: 12px;
    height: 12px;
    border: 2px solid var(--black-5);
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:before{
    border-color: var(--black-1);
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 8px;
    height: 8px;
    background: var(--black-1);
    position: absolute;
    top: 5px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

@media (max-width: 767px) {

    .mentorProfileContainer{
        padding: 30px 15px;
    }

    .mentorProfileOuter{
        height: calc(100vh - 69px);
        overflow: hidden;
    }

    .mentorProfile {
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 5px !important;
    }

    .personalDetailContainer .cardsOuter{
        flex-wrap: wrap;
    }

    .personalDetailContainer .card{
        width: 100%;
    }

    .mentorDetails .mentorName {
        font-size: 24px;
    }

    .linkdinBtn{
        font-size: 14px;
    }

    .mentorDetails .designation {
        font-size: 16px;
    }

    .mentorDetails{
        gap: 5px;
    }
}

.flexbox{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.weekdayBox:not(:last-child){
    border-bottom: 1px solid var(--grey-3);
}

.weekdayBox{
    max-width: 600px;

    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    gap: 30px;
    padding: 18px;
    color: var(--black-2);
}

.unavailable{
    color: var(--black-5);
}

.weekday{
    font-family: 'Inter Medium';
    min-width: 40px;
}

.dayAvaiability{
    gap: 20px;
    width: 100%;
    flex-wrap: wrap;
}

.timeWrapBox{
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.stroke{
    height: 1px;
    width: 10px;
    background: var(--black-2);
    display: block;
    position: relative;
}
.menuOuter {
  width: 290px;
  background: var(--white);
  border-right: 2px solid var(--grey-3);
  height: 100%;
  padding: var(--spacing-28px) var(--spacing-40px);
}

.menuList {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: var(--spacing-24px);
}

.menuListItem {
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-8px);
  width: 100%;
  padding: var(--spacing-12px) 0;
  color: var(--grey-1);
  cursor: pointer;
  transition: 0.5s;
}

.menuListItem:hover,
.menuListItem.active {
  color: var(--primary-4);
}

.menuListItem:hover svg path,
.menuListItem.active svg path {
  stroke: var(--primary-4);
  fill: var(--primary-4);
}

.adminProfile {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--spacing-20px);
  margin-bottom: 20px;
}

.adminImg {
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid var(--grey-3);
}
.adminProfileContent {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4px);
}

.adminProfileContent .name {
  font-size: 20px;
  font-family: "Inter Bold";
}

.greyText {
  color: var(--grey-1);
}

.adminProfileContent .greyText {
  font-size: 18px;
  /* cursor: pointer; */
}
.menuListItembtn {
  display: flex;
  padding: 20px 22px;
  gap: var(--spacing-8px);
  align-items: center;
  gap: 20px;
  width: 248px;
  height: 60px;
  background: #fafafa;
  border-radius: 6px;
  cursor: pointer;
}

.menuFlex {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.menuListItem.logoutToggler:hover svg path,
.menuListItem.logoutToggler.active svg path{
    fill: none !important;
    stroke: var(--primary-4);
}


@media (max-width: 767px) {
  .menuOuter {
    position: absolute;
    left: -100%;
    width: 100%;
    transition: 0.4s ease-in-out;
    z-index: 999;
    /* height: 100%; */
    height: calc(100% - 69px);
    overflow: auto;
    border-right: none;
    padding: 20px;
  }

  .menuOuter.active {
    left: 0;
    transition: 0.4s ease-in-out;
  }

  .menuList {
    margin-top: 0;
  }

  .adminProfile {
    margin-bottom: 20px;
    align-items: center;
    border-bottom: 1px solid var(--grey-3);
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  .adminProfileContent .greyText {
    font-size: 14px;
  }

  .menuListItem {
    font-size: 16px;
    padding: 10px 0;
    border-bottom: 1px solid var(--grey-3);
  }

  .menuListItem svg {
    width: 18px;
  }
}

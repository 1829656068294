header {
  width: 100%;
  display: block;
  position: fixed;
  top: 0;
  z-index: 100;
  background: var(--grey-5);
  padding: var(--spacing-20px) var(--spacing-40px);
  border-bottom: 2px solid var(--grey-3);
}
.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.headerContainer .logo {
  width: 150px;
}

header.headerMenu .headerRight {
  display: flex;
}

header .headerRight {
  display: none;
}
.userProfile {
  width: 40px;
  border-radius: 50%;
  height: 40px;
  object-fit: cover;
}

.headerRight {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.hamburger-menu{
  display: none;
}

@media (max-width: 767px) {

  header{
    padding: 20px;
  }

  .hamburger-menu{
    display: block;
    position: relative;
    max-width: 30px;
    width: 100%;
    line-height: 100%;
  }

  .hamburger-menu .ham-lines {
    display: block;
    width: 100%;
    height: 3px;
    background: var(--black-1);
    margin-top: 6px;
    position: relative;
    transition: 0.2s;
    opacity: 1;
  }

  .hamburger-menu .ham-lines:first-child {
    margin-top: 0;
  }

  .hamburger-menu.active .ham-lines:nth-child(1) {
    transform: rotate(45deg);
    margin: 0;
    top: 6px;
    transition: 0.2s;
  }

  .hamburger-menu.active .ham-lines:nth-child(2) {
    transform: rotate(-45deg);
    left: 0;
    top: 4px;
    margin: 0;
    transition: 0.2s;
  }

  .hamburger-menu.active .ham-lines:last-child {
    opacity: 0;
    visibility: hidden;
  }

  .hamburger-menu.active .ham-lines {
    width: 70%;
    height: 2px;
  }

}
.feedbackContainerOuter{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 65px);
    justify-content: flex-start;
}
.feedbackContainer{
    max-width: 700px;
    width: 100%;
    height: 100%;
    padding: var(--spacing-28px);
    padding-right: 0;
    display: flex;
    overflow: auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.feedbackContainer .cardsOuter{
    flex-direction: row;
}

.feedbackContainer .card{
    width: max-content;
    justify-content: flex-start;
    padding: var(--spacing-12px);
}

.feedbackContainer .card img{
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
}
.feedbackContainer .card .cardDetails{
    display: flex;
    flex-direction: column;
}

.feedbackContainer .cardDetails{
    gap: var(--spacing-4px);
}

.feedbackContainer .cardDetails .name{
    font-size: 18px;
    font-family: 'Inter Semi Bold';
}

.feedbackContainer .cardDetails .email{
    font-size: 12px;
    color: var(--black-3);
}

.formGroup{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: var(--spacing-8px);
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: var(--spacing-20px);
}
.dashboardContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 65px);
    justify-content: flex-start;
}

.pageHeader{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-20px);
    border-bottom: 2px solid var(--grey-3);
}

.pageHeader .heading{
    font-size: 20px;
    font-family: 'Inter Semi Bold';
}

.tableContainer{
    width: 100%;
    height: 100%;
    overflow: auto;
}

.studentTable{
    width: 100%;
    text-align: left;
}

.thead{
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
}

.dashboardContainer table th{
    font-family: 'Inter Light BETA';
    background: var(--black-1);
    color: var(--white);
    padding: var(--spacing-20px) var(--spacing-28px);
}


.dashboardContainer table td{
    font-family: 'Inter Regular';
    font-size: 16px;
    color: var(--black-3);
    border-bottom: 1px solid var(--grey-3);
    padding: var(--spacing-12px) var(--spacing-28px);
}

.primaryBtn{
    padding: var(--spacing-8px) var(--spacing-16px);
    border-radius: 5px;
    border: 1px solid var(--primary-5);
    background: var(--primary-5);
    color: var(--white);
    cursor: pointer;
    transition: 0.3s;
}

.primaryBtn:hover{
    box-shadow: 3px 3px var(--primary-2);
}

.studentInfo img{
    border: 1px solid var(--grey-3);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    object-fit: cover;
}
.studentInfo{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-4px);
}

.mentorBtn{
    max-width: max-content;
}

.dotIcon{
    cursor: pointer;
    position: relative;
}

.pullDownWrapper{
    position: relative;
}

.pullDownMenu{
    position: absolute;
    left: -70px;
    bottom: -80px;
    height: auto;
    width: auto;
    min-width: 130px;
    border: 1px solid var(--grey-3);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: 0.2s ease-in-out;
    background: var(--white);
}

.pullDownMenu::before{
    content: '';
    width: 5px;
    height: 5px;
    border: solid white;
    border-width: 5px 0px 0px 5px;
    transform: rotate(45deg);
    position: absolute;
    right: 55px;
    top: -5px;
    z-index: -1;
    box-shadow: -1px -1px var(--grey-3);
}

.pullDownMenu.active{
    visibility: visible;
    opacity: 1;
    z-index: 1;
    transition: 0.2s ease-in-out;
}

.pullMenu{
    padding: 10px 15px;
    font-size: 14px;
    width: 100%;
    transition: 0.4s ease-in-out;
    cursor: pointer;
}

.pullMenu:hover{
    background: var(--grey-4);
    transition: 0.4s ease-in-out;
}

.pullMenu:not(:last-child){
    border-bottom: 1px solid var(--grey-3);
}

@media (max-width: 767px){
    .pageHeader{
        padding: 10px 15px;
        min-height: 61px;
    }

    .tableContainer{
        max-width: 100%;
        overflow: auto;
        display: block;
        border: 1px solid var(--grey-3);
    }

    .tableWrap{
        padding: 30px 15px;
        max-height: calc(100% - 62px);
    }

    .dashboardContainer table td{
        min-width: 150px;
        padding: 10px;
        font-size: 14px;
    }

    .dashboardContainer table th{
        min-width: 150px;
        padding: 15px;
        font-size: 14px;
    }

    .dashboardContainer{
        height: calc(100vh - 69px);
        overflow: hidden;
    }


}
.addMentorPopup .popupHeader{
    padding: 16px 40px;
    font-size: 14px;
}

.addMentorPopup .formGroups{
    width: 100%;
}

.addMentorPopup .popupContainer{
    width: 970px;
    border-radius: 5px;
    height: max-content;
}

.addMentorPopup .popupBody{
    padding: 40px;
    height: 34rem;
    overflow-y: auto;
}

.flexWrapBox{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.addMentorPopup .ButtonGroup button{
    justify-content: flex-start;
    gap: 5px;
    line-height: 120%;
    max-width: max-content;
}

.addMentorPopup .ButtonGroup{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-top: 60px;
}

.closeWhite{
    filter: invert(1);
}

@media (max-width: 767px){
    .addMentorPopup .popupBody {
        padding: 30px 15px;
    }

    .addMentorPopup .popupHeader {
        padding: 15px 15px;
    }
}
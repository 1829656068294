.personalDetailContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: var(--spacing-12px);
}

.personalDetailContainer .card{
    width: 48%;
    padding: var(--spacing-12px);
    justify-content: flex-start;
}

.personalDetailContainer .card .heading{
    font-size: 13px;
    color: var(--black-5);
}

.personalDetailContainer .card .content{
    font-size: 14px;
    font-family: 'Inter Medium';
    color: var(--black-3);
}


.personalDetailContainer .cardsOuter{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: var(--spacing-12px);
    padding: var(--spacing-16px) 0;
    margin: 0;
    border-bottom: 1px solid var(--grey-3);
}

.mentorRequest{
    max-width: 650px;
}

.mentorRequest .heading{
    font-size: 18px;
}

.personalDetailOuter:not(:first-child){
    margin-top: var(--spacing-24px);
}

.personalDetailOuter:not(:first-child) .cardsOuter{
    border: none;
}

.dangerOutlineBtn{
    cursor: pointer;
    background: var(--white);
    border-radius: 5px;
    color: var(--danger-5);
    border: 1px solid var(--danger-5);
    padding: var(--spacing-8px) var(--spacing-16px);
    transition: 0.3s;
}
.dangerOutlineBtn:hover{
    box-shadow: 3px 3px var(--danger-2);
}
.btnGrp{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-8px);
}
.profileHeader{
    padding: 30px;
    border-bottom: 1px solid var(--grey-3);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.profileHeader.withbutton{
    padding: 12px 30px;
}

.profileHeader button{
    max-width: max-content;
    padding: 10px 15px;
}

.profileHeader .blueButton{
    font-size: 14px;
    line-height: 100%;
}

.profileMentorWrapper{
    padding: 28px 30px;
}

.profileMentor{
    gap: 25px;
}

.profileMentor .mentorDetails{
    margin-left: 0;
    gap: 15px;
}

.profileMentor .mentorDetails .mentorName{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.accountDetailsWrap{
    margin-top: var(--spacing-28px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0 5px;
    border-bottom: 2px solid var(--grey-3);
    max-width: 630px;
}

.detailsHeading{
    font-size: 16px;
    line-height: 120%;
    color: var(--primary-5);
    position: relative;
    font-family: 'Inter Regular';
}

.detailsHeading::after{
    content: "";
    position: absolute;
    left: 0;
    bottom: -7px;
    height: 2px;
    width: 100%;
    background: var(--primary-5);
}

.profileMentorWrapper .personalDetailOuter .card{
    max-width: 300px;
}

.profileMentorWrapper .heading{
    font-size: 18px;
    line-height: 120%;
    font-family: 'inter semi bold';
}

.profileMentorWrapper .personalDetailOuter{
    max-width: 630px;
}

.divider{
    display: block;
    position: relative;
    border-top: 2px solid var(--grey-3);
}

.profileMentorWrapper .cardsOuter{
    padding: 0;
}

.topBorderMentor{
    border-top: 1px solid var(--grey-3);
    margin-top: 20px !important;
    padding-top: 20px;
}

@media (max-width: 767px) {
    .profileHeader {
        padding: 10px 15px;
        min-height: 61px;
    }
    .profileMentor .mentorDetails{
        gap: 5px;
    }

    .profileMentor .mentorDetails .mentorName{
        font-size: 24px;
    }

    .profileWrapper{
        height: calc(100vh - 69px);
        overflow: hidden;
    }

    .profileMentorWrapper{
        padding: 28px 30px;
        max-height: calc(100% - 60px);
        overflow: auto;
    }
}